<template>
    <div>
        <doctors-component/>
        <footer-component/>
    </div>
</template>

<script>
    import DoctorsComponent from "@/components/medical-services/DoctorsComponent";
    import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
    
    export default {
        name: "Doctors",
        title: "Médicos Especialistas | Turismo BC",
        components: {
            DoctorsComponent,
            FooterComponent
        }
    }
</script>

<style scoped>

</style>